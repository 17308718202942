@tailwind base;
@tailwind components;
@tailwind utilities;

/* poppins-regular - latin */
@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  src: url("../src/Assets/font/poppins-v20-latin-regular.eot"); /* IE9 Compat Modes */
  src: local(""),
    url("../src/Assets/font/poppins-v20-latin-regular.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */ url("../src/Assets/font/poppins-v20-latin-regular.woff2")
      format("woff2"),
    /* Super Modern Browsers */
      url("../src/Assets/font/poppins-v20-latin-regular.woff") format("woff"),
    /* Modern Browsers */
      url("../src/Assets/font/poppins-v20-latin-regular.ttf") format("truetype"),
    /* Safari, Android, iOS */
      url("../src/Assets/font/poppins-v20-latin-regular.svg#Poppins")
      format("svg"); /* Legacy iOS */
}
/* poppins-500 - latin */
@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  src: url("../src/Assets/font/poppins-v20-latin-500.eot"); /* IE9 Compat Modes */
  src: local(""),
    url("../src/Assets/font/poppins-v20-latin-500.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */ url("../src/Assets/font/poppins-v20-latin-500.woff2")
      format("woff2"),
    /* Super Modern Browsers */
      url("../src/Assets/font/poppins-v20-latin-500.woff") format("woff"),
    /* Modern Browsers */ url("../src/Assets/font/poppins-v20-latin-500.ttf")
      format("truetype"),
    /* Safari, Android, iOS */
      url("../src/Assets/font/poppins-v20-latin-500.svg#Poppins") format("svg"); /* Legacy iOS */
}
/* poppins-600 - latin */
@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  src: url("../src/Assets/font/poppins-v20-latin-600.eot"); /* IE9 Compat Modes */
  src: local(""),
    url("../src/Assets/font/poppins-v20-latin-600.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */ url("../src/Assets/font/poppins-v20-latin-600.woff2")
      format("woff2"),
    /* Super Modern Browsers */
      url("../src/Assets/font/poppins-v20-latin-600.woff") format("woff"),
    /* Modern Browsers */ url("../src/Assets/font/poppins-v20-latin-600.ttf")
      format("truetype"),
    /* Safari, Android, iOS */
      url("../src/Assets/font/poppins-v20-latin-600.svg#Poppins") format("svg"); /* Legacy iOS */
}
/* poppins-700 - latin */
@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  src: url("../src/Assets/font/poppins-v20-latin-700.eot"); /* IE9 Compat Modes */
  src: local(""),
    url("../src/Assets/font/poppins-v20-latin-700.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */ url("../src/Assets/font/poppins-v20-latin-700.woff2")
      format("woff2"),
    /* Super Modern Browsers */
      url("../src/Assets/font/poppins-v20-latin-700.woff") format("woff"),
    /* Modern Browsers */ url("../src/Assets/font/poppins-v20-latin-700.ttf")
      format("truetype"),
    /* Safari, Android, iOS */
      url("../src/Assets/font/poppins-v20-latin-700.svg#Poppins") format("svg"); /* Legacy iOS */
}

html {
  height: 100%;
  overflow-y: hidden;
}

body {
  margin: 0;
  height: 100%;
  background-color: #f1f4fa;
  font-family: "Poppins", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* width */
::-webkit-scrollbar {
  width: 0px;
}

body::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

body::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  outline: 1px solid slategrey;
  scroll-behavior: smooth;
}

.checkboxStyle:checked + .labelStyle,
.checkboxStyle:checked .labelStyle > svg {
  @apply bg-primary fill-white text-white;
}

.grid-cols-21 {
  grid-template-columns: repeat(21, 1fr);
}

.movies-scroll::-webkit-scrollbar-thumb {
  background-color: rgba(128, 128, 128, 0.241);
  border-radius: 10px;
  transition-duration: 1s;
}
.movies-scroll::-webkit-scrollbar-thumb:hover {
  background-color: rgba(128, 128, 128, 0.559);
  border-radius: 10px;
}
.movies-scroll::-webkit-scrollbar-corner {
  background-color: transparent;
}
.movies-scroll::-webkit-scrollbar {
  width: 10px;
}

.movies-scroll {
  scrollbar-color: #6969dd #e0e0e0;
  scrollbar-width: thin;
}

.my-scroll-container {
  position: relative;
  margin-bottom: 20px;
}

.my-scroll-container::-webkit-scrollbar {
  height: 8px;
  width: 8px;
}

.my-scroll-container::-webkit-scrollbar-track {
  background-color: transparent;
}

.my-scroll-container::-webkit-scrollbar-thumb {
  background-color: rgba(128, 128, 128, 0.241);
  border-radius: 8px;
}

.my-scroll-container .indiana-scroll-container__arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 24px;
  height: 24px;
  background-color: #eee;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.my-scroll-container .indiana-scroll-container__arrow.indiana-scroll-container__arrow--prev {
  left: 0;
}

.my-scroll-container .indiana-scroll-container__arrow.indiana-scroll-container__arrow--next {
  right: 0;
}

.react-calendar__tile:disabled {
  background-color: #aaaaaa;
}
.react-calendar__month-view__days__day--neighboringMonth {
  color: inherit;
}
.scan_table::-webkit-scrollbar-thumb {
  background-color: rgba(128, 128, 128, 0.241);
  border-radius: 10px;
  transition-duration: 1s;
}
.scan_table::-webkit-scrollbar-thumb:hover {
  background-color: rgba(128, 128, 128, 0.559);
  border-radius: 10px;
}
.scan_td_wrap {
  word-break: break-word;
}

.scan_table::-webkit-scrollbar {
  width: 10px;
}

.scan_table {
  scrollbar-color: #6969dd #e0e0e0;
  scrollbar-width: thin;
}

.coco {
  visibility: hidden;
}

.drop-down-list-showTime:hover .popup-showTim {
  display: block;
}
.popup-showTim {
  display: none;
}

.mantine-TimeField-timeInput:focus,
.mantine-AmPmInput-amPmInput:focus {
  border-color: transparent;
  box-shadow: 0 0 0 0.2rem rgba(128, 128, 128, 0);

  outline: none;
}
.mantine-TimeField-timeInput,
.mantine-AmPmInput-amPmInput {
  border-color: transparent;
  box-shadow: 0 0 0 0.2rem rgba(128, 128, 128, 0);
  outline: none;

  background-color: transparent;
  padding: 0;
  font-size: 14px;
  line-height: 0;
}
.mantine-1afvv4w,
.mantine-1azztlu {
  width: 50px !important;
}
@media print {
  .ticket_printer {
    display: block;
    page-break-after: always;
  }
}
@media print {
  .printLandscape {
    width: 1463px;
    height: 1017px;
  }
}
@media print {
  .print {
    margin-left: 20px;
    margin-right: 20px;
    height: 1463px;
    width: 1034px;
  }
}
@media (min-width: 768px) and (max-width: 1024px) {
  .special_screen {
    scale: .8;
    margin-left: -35px;
  }
}

.mantine-TimeRangeInput-input {
  border-radius: 10px;
  border: 1px solid white !important;

  height: 40px;

  font-size: 14px;
  font-weight: 500;
  color: #000;
  outline: none;
  background-color: #fff;
  box-shadow: 0px 8px 9px 0px rgba(0, 0, 0, 0.1);
}
.mantine-DateRangePicker-input {
  width: 250px !important;

  border-radius: 10px;
  border: 1px solid white !important;
  padding: 10px;
  width: 100%;
  height: 100%;
  font-size: 14px;
  font-weight: 500;
  color: #000;
  outline: none;
  background-color: #fff;
  box-shadow: 0px 8px 9px 0px rgba(0, 0, 0, 0.1);
}
.innerShadow {
  box-shadow: inset 0px 0px 10px 2px rgba(216, 160, 5, 0.313);
}

.CFC-Screen-01 {
  gap: 4px;
  grid-template-areas:
    "K1 K2 K3 K4 K5 K6 K7 K8 K9 K10 K11 K12 K13 K14 K15 . "
    "J17 J16 J15 J14 J13 J12 J11 . . . J1 J2 J3 J4 J5 J6"
    ". . . . . . . . . . I1 I2 I3 I4 I5 I6"
    "H17 H16 H15 H14 H13 H12 H11 . . . H1 H2 H3 H4 H5 H6"
    "G17 G16 G15 G14 G13 G12 G11 . . . G1 G2 G3 G4 G5 G6"
    "F17 F16 F15 F14 F13 F12 F11 . . . F1 F2 F3 F4 F5 ."
    "E17 E16 E15 E14 E13 E12 E11 . . . E1 E2 E3 E4 E5 ."
    "D17 D16 D15 D14 D13 D12 D11 . . . D1 D2 D3 D4 D5 ."
    "C17 C16 C15 C14 C13 C12 C11 . . . C1 C2 C3 C4 C5 ."
    "B17 B16 B15 B14 B13 B12 B11 . . . B1 B2 B3 B4 B5 ."
    "A17 A16 A15 A14 A13 A12 A11 . . . A1 A2 A3 A4 A5 .";
}

.CFC-Screen-02 {
  gap: 4px;
  grid-template-areas:
    "K1 K2 K3 K4 K5 K6 K7 K8 K9 K10 K11 K12 K13 K14 K15 K16 K17"
    "J17 J16 J15 J14 J13 J12 J11 . . . J1 J2 J3 J4 J5 J6 J7"
    "I17 I16 I15 I14 I13 I12 I11 . . . . . . . . . ."
    "H17 H16 H15 H14 H13 H12 H11 . . . H1 H2 H3 H4 H5 H6 H7"
    "G17 G16 G15 G14 G13 G12 G11 . . . G1 G2 G3 G4 G5 G6 G7"
    "F17 F16 F15 F14 F13 F12 F11 . . . F1 F2 F3 F4 F5 F6 F7"
    "E17 E16 E15 E14 E13 E12 E11 . . . E1 E2 E3 E4 E5 E6 E7"
    "D17 D16 D15 D14 D13 D12 D11 . . . D1 D2 D3 D4 D5 D6 D7"
    "C17 C16 C15 C14 C13 C12 C11 . . . C1 C2 C3 C4 C5 C6 C7"
    "B17 B16 B15 B14 B13 B12 B11 . . . B1 B2 B3 B4 B5 B6 B7"
    "A17 A16 A15 A14 A13 A12 A11 . . . A1 A2 A3 A4 A5 A6 A7";
}

.CFC-Screen-03 {
  gap: 4px;
  grid-template-areas:
    ". K19 K18 K17 K16 K15 K14 K13 K12 K11 . . . . . . . "
    "J17 J16 J15 J14 J13 J12 J11 . . . . . J1 J2 J3 J4 J5"
    "I17 I16 I15 I14 I13 I12 I11 . . . I1 I2 I3 I4 I5 I6 I7"
    ". . . . . . . . . . H1 H2 H3 H4 H5 H6 H7"
    "G17 G16 G15 G14 G13 G12 G11 . . . G1 G2 G3 G4 G5 G6 G7"
    "F17 F16 F15 F14 F13 F12 F11 . . . F1 F2 F3 F4 F5 F6 F7"
    "E17 E16 E15 E14 E13 E12 E11 . . . E1 E2 E3 E4 E5 E6 E7"
    "D17 D16 D15 D14 D13 D12 D11 . . . D1 D2 D3 D4 D5 D6 D7"
    "C17 C16 C15 C14 C13 C12 C11 . . . C1 C2 C3 C4 C5 C6 C7"
    "B17 B16 B15 B14 B13 B12 B11 . . . B1 B2 B3 B4 B5 B6 B7"
    "A17 A16 A15 A14 A13 A12 A11 . . . A1 A2 A3 A4 A5 A6 A7";
}

.CFC-Screen-04 {
  gap: 4px;
  grid-template-areas:
    "JJ37 JJ36 JJ35 JJ34 JJ33 JJ32 JJ31 . . . JJ1 JJ2 JJ3 JJ4 JJ5 JJ6 JJ7 JJ8 JJ9 JJ10 JJ11 JJ12 JJ13 JJ14 JJ15 . . . JJ21 JJ22 JJ23 JJ24 JJ25 . ."
    "II37 II36 II35 II34 II33 II32 II31 . . . II1 II2 II3 II4 II5 II6 II7 II8 II9 II10 II11 II12 II13 II14 II15 . . . II21 II22 II23 II24 II25 II26 II27"
    "HH37 HH36 HH35 HH34 HH33 HH32 HH31 . . . HH1 HH2 HH3 HH4 HH5 HH6 HH7 HH8 HH9 HH10 HH11 HH12 HH13 HH14 HH15 . . . HH21 HH22 HH23 HH24 HH25 HH26 HH27"
    "GG37 GG36 GG35 GG34 GG33 GG32 GG31 . . . GG1 GG2 GG3 GG4 GG5 GG6 GG7 GG8 GG9 GG10 GG11 GG12 GG13 GG14 GG15 . . . GG21 GG22 GG23 GG24 GG25 GG26 GG27"
    "FF37 FF36 FF35 FF34 FF33 FF32 FF31 . . . FF1 FF2 FF3 FF4 FF5 FF6 FF7 FF8 FF9 FF10 FF11 FF12 FF13 FF14 FF15 . . . FF21 FF22 FF23 FF24 FF25 FF26 FF27"
    "EE37 EE36 EE35 EE34 EE33 EE32 EE31 . . . EE1 EE2 EE3 EE4 EE5 EE6 EE7 EE8 EE9 EE10 EE11 EE12 EE13 EE14 EE15 . . . EE21 EE22 EE23 EE24 EE25 EE26 EE27"
    "DD37 DD36 DD35 DD34 DD33 DD32 DD31 . . . DD1 DD2 DD3 DD4 DD5 DD6 DD7 DD8 DD9 DD10 DD11 DD12 DD13 DD14 DD15 . . . DD21 DD22 DD23 DD24 DD25 DD26 DD27"
    "CC37 CC36 CC35 CC34 CC33 CC32 CC31 . . . CC1 CC2 CC3 CC4 CC5 CC6 CC7 CC8 CC9 CC10 CC11 CC12 CC13 CC14 CC15 . . . CC21 CC22 CC23 CC24 CC25 CC26 CC27"
    "BB37 BB36 BB35 BB34 BB33 BB32 BB31 . . . BB1 BB2 BB3 BB4 BB5 BB6 BB7 BB8 BB9 BB10 BB11 BB12 BB13 BB14 BB15 . . . BB21 BB22 BB23 BB24 BB25 BB26 BB27"
    "AA37 AA36 AA35 AA34 AA33 AA32 AA31 . . . AA1 AA2 AA3 AA4 AA5 AA6 AA7 AA8 AA9 AA10 AA11 AA12 AA13 AA14 AA15 . . . AA21 AA22 AA23 AA24 AA25 AA26 AA27"
    "G37 G36 G35 G34 G33 G32 G31 . . . . . . . . . . . . . . . . . . . . . G21 G22 G23 G24 G25 G26 G27"
    "F37 F36 F35 F34 F33 F32 F31 . . . F1 F2 F3 F4 F5 F6 F7 F8 F9 F10 F11 F12 F13 F14 F15 . . . F21 F22 F23 F24 F25 F26 F27"
    "E37 E36 E35 E34 E33 E32 E31 . . . E1 E2 E3 E4 E5 E6 E7 E8 E9 E10 E11 E12 E13 E14 E15 . . . E21 E22 E23 E24 E25 E26 E27"
    "D37 D36 D35 D34 D33 D32 D31 . . . D1 D2 D3 D4 D5 D6 D7 D8 D9 D10 D11 D12 D13 D14 D15 . . . D21 D22 D23 D24 D25 D26 D27"
    "C37 C36 C35 C34 C33 C32 C31 . . . C1 C2 C3 C4 C5 C6 C7 C8 C9 C10 C11 C12 C13 C14 C15 . . . C21 C22 C23 C24 C25 C26 C27"
    "B37 B36 B35 B34 B33 B32 B31 . . . B1 B2 B3 B4 B5 B6 B7 B8 B9 B10 B11 B12 B13 B14 B15 . . . B21 B22 B23 B24 B25 B26 B27"
    "A37 A36 A35 A34 A33 A32 A31 . . . A1 A2 A3 A4 A5 A6 A7 A8 A9 A10 A11 A12 A13 A14 A15 . . . A21 A22 A23 A24 A25 A26 A27";
}

.CFC-Screen-05 {
  gap: 4px;
  grid-template-areas:
    "K14 K13 K12 K11 . . . K1 K2 K3 K4 K5 K6 K7 K8 K9 K10"
    "J13 J12 J11 . . . . . . . J1 J2 J3 J4 J5 J6 J7"
    "I17 I16 I15 I14 I13 I12 I11 . . . I1 I2 I3 I4 I5 I6 I7"
    "H17 H16 H15 H14 H13 H12 H11 . . . . . . . . . ."
    "G17 G16 G15 G14 G13 G12 G11 . . . G1 G2 G3 G4 G5 G6 G7"
    "F17 F16 F15 F14 F13 F12 F11 . . . F1 F2 F3 F4 F5 F6 F7"
    "E17 E16 E15 E14 E13 E12 E11 . . . E1 E2 E3 E4 E5 E6 E7"
    "D17 D16 D15 D14 D13 D12 D11 . . . D1 D2 D3 D4 D5 D6 D7"
    "C17 C16 C15 C14 C13 C12 C11 . . . C1 C2 C3 C4 C5 C6 C7"
    "B17 B16 B15 B14 B13 B12 B11 . . . B1 B2 B3 B4 B5 B6 B7"
    "A17 A16 A15 A14 A13 A12 A11 . . . A1 A2 A3 A4 A5 A6 A7";
}
.CFC-Screen-06 {
  gap: 4px;
  grid-template-areas:
    "K1 K2 K3 K4 K5 K6 K7 K8 K9 K10 K11 K12 K13 K14 K15 K16 K17"
    "J17 J16 J15 J14 J13 J12 J11 . . . J1 J2 J3 J4 J5 J6 J7"
    ". . . . . . . . . . I1 I2 I3 I4 I5 I6 I7"
    "H17 H16 H15 H14 H13 H12 H11 . . . H1 H2 H3 H4 H5 H6 H7"
    "G17 G16 G15 G14 G13 G12 G11 . . . G1 G2 G3 G4 G5 G6 G7"
    "F17 F16 F15 F14 F13 F12 F11 . . . F1 F2 F3 F4 F5 F6 F7"
    "E17 E16 E15 E14 E13 E12 E11 . . . E1 E2 E3 E4 E5 E6 E7"
    "D17 D16 D15 D14 D13 D12 D11 . . . D1 D2 D3 D4 D5 D6 D7"
    "C17 C16 C15 C14 C13 C12 C11 . . . C1 C2 C3 C4 C5 C6 C7"
    "B17 B16 B15 B14 B13 B12 B11 . . . B1 B2 B3 B4 B5 B6 B7"
    "A17 A16 A15 A14 A13 A12 A11 . . . A1 A2 A3 A4 A5 A6 A7";
}

.CFC-Screen-07 {
  gap: 4px;
  grid-template-areas:
    "K1 K2 K3 K4 K5 K6 K7 K8 K9 K10 K11 K12 K13 K14 K15 K16 K17"
    "J17 J16 J15 J14 J13 J12 J11 . . . J1 J2 J3 J4 J5 J6 J7"
    "I17 I16 I15 I14 I13 I12 I11 . . . . . . . . . ."
    "H17 H16 H15 H14 H13 H12 H11 . . . H1 H2 H3 H4 H5 H6 H7"
    "G17 G16 G15 G14 G13 G12 G11 . . . G1 G2 G3 G4 G5 G6 G7"
    "F17 F16 F15 F14 F13 F12 F11 . . . F1 F2 F3 F4 F5 F6 F7"
    "E17 E16 E15 E14 E13 E12 E11 . . . E1 E2 E3 E4 E5 E6 E7"
    "D17 D16 D15 D14 D13 D12 D11 . . . D1 D2 D3 D4 D5 D6 D7"
    "C17 C16 C15 C14 C13 C12 C11 . . . C1 C2 C3 C4 C5 C6 C7"
    "B17 B16 B15 B14 B13 B12 B11 . . . B1 B2 B3 B4 B5 B6 B7"
    "A17 A16 A15 A14 A13 A12 A11 . . . A1 A2 A3 A4 A5 A6 A7";
}

.CFC-Screen-08 {
  gap: 4px;
  grid-template-areas:
    ". . . . . M13 M12 M11 . . . . . . . . . . . . . . . . . . . . "
    ". . . . L14 L13 L12 L11 . . . . . . . . . . . . . . . . L21 L22 L23 L24"
    "K18 K17 K16 K15 K14 K13 K12 K11 . . K1 K2 K3 K4 K5 K6 K7 K8 . . K21 K22 K23 K24 K25 K26 K27 K28"
    "J18 J17 J16 J15 J14 J13 J12 J11 . . J1 J2 J3 J4 J5 J6 J7 J8 . . J21 J22 J23 J24 J25 J26 J27 J28"
    "I18 I17 I16 I15 I14 I13 I12 I11 . . I1 I2 I3 I4 I5 I6 I7 I8 . . I21 I22 I23 I24 I25 I26 I27 I28"
    "H18 H17 H16 H15 H14 H13 H12 H11 . . H1 H2 H3 H4 H5 H6 H7 H8 . . H21 H22 H23 H24 H25 H26 H27 H28"
    "G18 G17 G16 G15 G14 G13 G12 G11 . . G1 G2 G3 G4 G5 G6 G7 G8 . . G21 G22 G23 G24 G25 G26 G27 G28"
    "F18 F17 F16 F15 F14 F13 F12 F11 . . F1 F2 F3 F4 F5 F6 F7 F8 . . F21 F22 F23 F24 F25 F26 F27 F28"
    "E18 E17 E16 E15 E14 E13 E12 E11 . . E1 E2 E3 E4 E5 E6 E7 E8 . . E21 E22 E23 E24 E25 E26 E27 E28"
    ". D17 D16 D15 D14 D13 D12 D11 . . D1 D2 D3 D4 D5 D6 D7 D8 . . D21 D22 D23 D24 D25 D26 D27 ."
    ". C17 C16 C15 C14 C13 C12 C11 . . C1 C2 C3 C4 C5 C6 C7 C8 . . C21 C22 C23 C24 C25 C26 C27 ."
    ". . B16 B15 B14 B13 B12 B11 . . B1 B2 B3 B4 B5 B6 B7 B8 . . B21 B22 B23 B24 B25 B26 . ."
    ". . . A15 A14 A13 A12 A11 . . A1 A2 A3 A4 A5 A6 A7 A8 . . A21 A22 A23 A24 A25 . . .";
}

.CFC-Screen-09 {
  gap: 4px;
  grid-template-areas:
    "  I1 I2 I3 I4 I5 I6 I7 I8 I9 I10 I11 I12 I13 I14 I15 . . . . . "
    "H18 H17 H16 H15 H14 H13 H12 H11 . .  H1 H2 H3 H4 H5 . . . . ."
    "G18 G17 G16 G15 G14 G13 G12 G11 . .  . . . . . . . . . ."
    "F18 F17 F16 F15 F14 F13 F12 F11 . .  F1 F2 F3 F4 F5 F6 F7 F8 F9 F10"
    "E18 E17 E16 E15 E14 E13 E12 E11 . .  E1 E2 E3 E4 E5 E6 E7 E8 E9 E10"
    "D18 D17 D16 D15 D14 D13 D12 D11 . .  D1 D2 D3 D4 D5 D6 D7 D8 D9 D10"
    "C18 C17 C16 C15 C14 C13 C12 C11 . .  C1 C2 C3 C4 C5 C6 C7 C8 C9 C10"
    "B18 B17 B16 B15 B14 B13 B12 B11 . .  B1 B2 B3 B4 B5 B6 B7 B8 B9 ."
    "A18 A17 A16 A15 A14 A13 A12 A11 . .  A1 A2 A3 A4 A5 A6 A7 A8 A9 .";
}
.CFC-Screen-10 {
  gap: 4px;
  grid-template-areas:
    ". . . . . I1 I2 I3 I4 I5 I6 I7 I8 I9 I10 I11 I12 I13"
    ". . . . . H14 H13 H12 H11 . .  H1 H2 H3 H4 H5 H6 H7"
    ". . . . . . . . . . .  G1 G2 G3 G4 G5 G6 G7"
    "F19 F18 F17 F16 F15 F14 F13 F12 F11 . .  F1 F2 F3 F4 F5 F6 F7"
    "E19 E18 E17 E16 E15 E14 E13 E12 E11 . .  E1 E2 E3 E4 E5 E6 E7 "
    "D19 D18 D17 D16 D15 D14 D13 D12 D11 . .  D1 D2 D3 D4 D5 D6 D7 "
    ". C18 C17 C16 C15 C14 C13 C12 C11 . .  C1 C2 C3 C4 C5 C6 C7 "
    ". B18 B17 B16 B15 B14 B13 B12 B11 . .  B1 B2 B3 B4 B5 B6 B7 "
    ". A18 A17 A16 A15 A14 A13 A12 A11 . .  A1 A2 A3 A4 A5 . . ";
}

.CFC-Screen-11 {
  gap: 4px;
  grid-template-areas:
    "I1 I2 I3 I4 I5 I6 I7 I8 I9 I10 I11 I12 I13 I14 I15 I16 I17 I18 I19 I20 I21"
    "H19 H18 H17 H16 H15 H14 H13 H12 H11 . . . H1 H2 H3 H4 H5 H6 H7 H8 H9"
    "G19 G18 G17 G16 G15 G14 G13 G12 G11 . . . G1 G2 G3 G4 G5 . . . ."
    "F19 F18 F17 F16 F15 F14 F13 F12 F11 . . . F1 F2 F3 F4 F5 . . . ."
    "E19 E18 E17 E16 E15 E14 E13 E12 E11 . . . . . . . . . . . ."
    "D19 D18 D17 D16 D15 D14 D13 D12 D11 . . . D1 D2 D3 D4 D5 D6 D7 D8 D9"
    "C19 C18 C17 C16 C15 C14 C13 C12 C11 . . . C1 C2 C3 C4 C5 C6 C7 C8 C9"
    "B19 B18 B17 B16 B15 B14 B13 B12 B11 . . . B1 B2 B3 B4 B5 B6 B7 B8 B9"
    "A19 A18 A17 A16 A15 A14 A13 A12 A11 . . . A1 A2 A3 A4 A5 A6 A7 A8 .";
}

/* CFC-Screen-12 */

.CFC-Screen-13 {
  gap: 4px;
  grid-template-areas:
    " . . . L10 L9 L8 L7 L6 L5 L4 L3 L2 L1 . . . L21 L22 L23 L24 L25 "
    " . . . . . K8 K7 K6 K5 K4 K3 K2 K1 . . . K21 K22 K23 K24 K25"
    " . . . . . J8 J7 J6 J5 J4 J3 J2 J1 . . . J21 J22 J23 J24 J25"
    " . . . . I9 I8 I7 I6 I5 I4 I3 I2 I1 . . . I21 I22 I23 I24 I25"
    "  . . . . . . . . . . . . . . . . H21 H22 H23 H24 H25"
    " G15 G14 G13 G12 G11  . . G1 G2 G3 G4 G5 G6 . . . G21 G22 G23 G24 G25"
    " F15 F14 F13 F12 F11  . . F1 F2 F3 F4 F5 F6 . . . F21 F22 F23 F24 F25"
    " E15 E14 E13 E12 E11  . . E1 E2 E3 E4 E5 E6 . . . E21 E22 E23 E24 E25"
    " D15 D14 D13 D12 D11  . . D1 D2 D3 D4 D5 D6 . . . D21 D22 D23 D24 D25"
    " C15 C14 C13 C12 C11  . . C1 C2 C3 C4 C5 C6 . . . C21 C22 C23 C24 C25"
    " . B14 B13 B12 B11 .  . B1 B2 B3 B4 B5 B6 . . . B21 B22 B23 B24 ."
    " . A14 A13 A12 A11 .  . A1 A2 A3 A4 A5 A6 . . . A21 A22 A23 A24 .";
}

.CFC-Screen-14 {
  gap: 4px;
  grid-template-areas:
    "L15 L14 L13 L12 L11 . . . L1 L2 L3 L4 L5 L6 L7 L8 L9 L10 . . . ."
    "K15 K14 K13 K12 K11 . . . K1 K2 K3 K4 K5 K6 K7 K8 . . . . . ."
    "J15 J14 J13 J12 J11 . . . J1 J2 J3 J4 J5 J6 J7 J8 . . . . . ."
    "I15 I14 I13 I12 I11 . . . I1 I2 I3 I4 I5 I6 I7 I8 I9 . . . . ."
    "H15 H14 H13 H12 H11 . . . . . . . . . . . . . . . . ."
    "G15 G14 G13 G12 G11 . . . G1 G2 G3 G4 G5 G6 . . . G21 G22 G23 G24 G25 "
    "F15 F14 F13 F12 F11 . . . F1 F2 F3 F4 F5 F6 . . . F21 F22 F23 F24 F25 "
    "E15 E14 E13 E12 E11 . . . E1 E2 E3 E4 E5 E6 . . . E21 E22 E23 E24 E25 "
    "D15 D14 D13 D12 D11 . . . D1 D2 D3 D4 D5 D6 . . . D21 D22 D23 D24 D25 "
    "C15 C14 C13 C12 C11 . . . C1 C2 C3 C4 C5 C6 . . . C21 C22 C23 C24 C25 "
    ". B14 B13 B12 B11 . . . B1 B2 B3 B4 B5 B6 . . . B21 B22 B23 B24 . "
    ". A14 A13 A12 A11 . . . A1 A2 A3 A4 A5 A6 . . . A21 A22 A23 A24 . ";
}

.CFC-Screen-15 {
  gap: 4px;
  grid-template-areas:
    "O19 O18 . .  O1 O2 O3 O4 O5 O6 O7 O8 O9 O10 O11 O12 O13 O14 O15 O16 O17 ."
    "N19 N18 N17 N16 N15 N14 N13 N12 N11 . . . N1 N2 N3 N4 N5 N6 N7 N8 N9 . "
    "M19 M18 M17 M16 M15 M14 M13 M12 M11 . . . M1 M2 M3 M4 M5 . . . . . "
    "L19 L18 L17 L16 L15 L14 L13 L12 L11 . . . L1 L2 L3 L4 L5 . . . . . "
    " K15 K14 K13 K12 K11 . . . . . . . . . . . . . . . . . "
    "J15 J14 J13 J12 J11 . . . J1 J2 J3 J4 J5 J6 . . . J21 J22 J23 J24 J25"
    "I15 I14 I13 I12 I11 . . . I1 I2 I3 I4 I5 I6 . . . I21 I22 I23 I24 I25"
    "H15 H14 H13 H12 H11 . . . H1 H2 H3 H4 H5 H6 . . . H21 H22 H23 H24 H25"
    "G15 G14 G13 G12 G11 . . . G1 G2 G3 G4 G5 G6 . . . G21 G22 G23 G24 G25"
    "F15 F14 F13 F12 F11 . . . F1 F2 F3 F4 F5 F6 . . . F21 F22 F23 F24 F25"
    "E15 E14 E13 E12 E11 . . . E1 E2 E3 E4 E5 E6 . . . E21 E22 E23 E24 E25"
    "D15 D14 D13 D12 D11 . . . D1 D2 D3 D4 D5 D6 . . . D21 D22 D23 D24 D25"
    "C15 C14 C13 C12 C11 . . . C1 C2 C3 C4 C5 C6 . . . C21 C22 C23 C24 C25"
    ". B14 B13 B12 B11 . . . B1 B2 B3 B4 B5 B6 . . . B21 B22 B23 B24 ."
    ". A14 A13 A12 A11 . . . A1 A2 A3 A4 A5 A6 . . . A21 A22 A23 A24 .";
}

.CFC-Screen-16 {
  gap: 4px;
  grid-template-areas:
    "O19 O18 O17 O16 O15 O14 O13 O12 O11 . . . O1 O2 O3 O4 O5 O6 O7 O8 O9 O10 "
    "N19 N18 N17 N16 N15 N14 N13 N12 N11 . . . N1 N2 N3 N4 . N5 N6 N7 N8 N9"
    ". . . . M15 M14 M13 M12 M11 . . . M1 M2 M3 M4 . M5 M6 M7 M8 M9"
    ". . . . L15 L14 L13 L12 L11 . . . L1 L2 L3 L4 . L5 L6 L7 L8 L9"
    ". . . . . . . . . . . . . . . . . K21 K22 K23 K24 K25"
    "J15 J14 J13 J12 J11 . . . J1 J2 J3 J4 J5 J6 . . . J21 J22 J23 J24 J25"
    "I15 I14 I13 I12 I11 . . . I1 I2 I3 I4 I5 I6 . . . I21 I22 I23 I24 I25"
    "H15 H14 H13 H12 H11 . . . H1 H2 H3 H4 H5 H6 . . . H21 H22 H23 H24 H25"
    "G15 G14 G13 G12 G11 . . . G1 G2 G3 G4 G5 G6 . . . G21 G22 G23 G24 G25"
    "F15 F14 F13 F12 F11 . . . F1 F2 F3 F4 F5 F6 . . . F21 F22 F23 F24 F25"
    "E15 E14 E13 E12 E11 . . . E1 E2 E3 E4 E5 E6 . . . E21 E22 E23 E24 E25"
    "D15 D14 D13 D12 D11 . . . D1 D2 D3 D4 D5 D6 . . . D21 D22 D23 D24 D25"
    ". C14 C13 C12 C11 . . . C1 C2 C3 C4 C5 C6 . . . C21 C22 C23 C24 ."
    ". B14 B13 B12 B11 . . . B1 B2 B3 B4 B5 B6 . . . B21 B22 B23 B24 ."
    ". A14 A13 A12 A11 . . . A1 A2 A3 A4 A5 A6 . . . A21 A22 A23 A24 .";
}

.CFC-Screen-17 {
  gap: 4px;
  grid-template-areas:
    "F1 F2 F3 F4 F5 F6"
    "E1 E2 E3 E4 E5 E6"
    "D1 D2 D3 D4 D5 D6"
    "C1 C2 C3 C4 C5 C6"
    "B1 B2 B3 B4 B5 B6"
    "A1 A2 A3 A4 A5 A6";
}
.CFC-Screen-18 {
  gap: 4px;
  grid-template-areas:
    "F1 F2 F3 F4 F5 F6"
    "E1 E2 E3 E4 E5 E6"
    "D1 D2 D3 D4 D5 D6"
    "C1 C2 C3 C4 C5 C6"
    "B1 B2 B3 B4 B5 B6"
    "A1 A2 A3 A4 A5 A6";
}
.CFC-Screen-19 {
  gap: 4px;
  grid-template-areas:
    "F1 F2 F3 F4 F5 F6"
    "E1 E2 E3 E4 E5 E6"
    "D1 D2 D3 D4 D5 D6"
    "C1 C2 C3 C4 C5 C6"
    "B1 B2 B3 B4 B5 B6"
    "A1 A2 A3 A4 A5 A6";
}
.CFC-Screen-20 {
  gap: 4px;
  grid-template-areas:
    "F1 F2 F3 F4 F5 F6"
    "E1 E2 E3 E4 E5 E6"
    "D1 D2 D3 D4 D5 D6"
    "C1 C2 C3 C4 C5 C6"
    "B1 B2 B3 B4 B5 B6"
    "A1 A2 A3 A4 A5 A6";
}

.QAT-Screen-01 {
  gap: 4px;
  grid-template-areas:
    "J13 J12 J11 J10 J9 J8 J7 J6 J5 J4 J3 J2 J1"
    ". . I9 I8 I7 I6 I5 I4 I3 I2 I1 . ."
    ". . H9 H8 H7 H6 H5 H4 H3 H2 H1 . ."
    ". G6 G5 G4 . . . . . G3 G2 G1 ."
    ". . . . . . . . . . . . ."
    ". . . . . . . . . . . . ."
    ". . . . . . . . . . . . ."
    ". . . . . . . . . . . . ."
    ". . . . . . . . . . . . ."
    ". . . . . . . . . . . . ."
    ". E11 E10 E9 E8 E7 E6 E5 E4 E3 E2 E1 ."
    ". D11 D10 D9 D8 D7 D6 D5 D4 D3 D2 D1 ."
    ". C11 C10 C9 C8 C7 C6 C5 C4 C3 C2 C1 ."
    ". B11 B10 B9 B8 B7 B6 B5 B4 B3 B2 B1 ."
    ". A11 A10 A9 A8 A7 A6 A5 A4 A3 A2 A1 .";
}
.QAT-Screen-02 {
  gap: 4px;
  grid-template-areas:
    "J13 J12 J11 J10 J9 J8 J7 J6 J5 J4 J3 J2 J1"
    ". . I9 I8 I7 I6 I5 I4 I3 I2 I1 . ."
    ". . H9 H8 H7 H6 H5 H4 H3 H2 H1 . ."
    ". G6 G5 G4 . . . . . G3 G2 G1 ."
    ". . . . . . . . . . . . ."
    ". . . . . . . . . . . . ."
    ". . . . . . . . . . . . ."
    ". . . . . . . . . . . . ."
    ". . . . . . . . . . . . ."
    ". . . . . . . . . . . . ."
    ". E11 E10 E9 E8 E7 E6 E5 E4 E3 E2 E1 ."
    ". D11 D10 D9 D8 D7 D6 D5 D4 D3 D2 D1 ."
    ". C11 C10 C9 C8 C7 C6 C5 C4 C3 C2 C1 ."
    ". B11 B10 B9 B8 B7 B6 B5 B4 B3 B2 B1 ."
    ". A11 A10 A9 A8 A7 A6 A5 A4 A3 A2 A1 .";
}
.QAT-Screen-03 {
  gap: 4px;
  grid-template-areas:
    "J13 J12 J11 J10 J9 J8 J7 J6 J5 J4 J3 J2 J1"
    ". . I9 I8 I7 I6 I5 I4 I3 I2 I1 . ."
    ". . H9 H8 H7 H6 H5 H4 H3 H2 H1 . ."
    ". G6 G5 G4 . . . . . G3 G2 G1 ."
    ". . . . . . . . . . . . ."
    ". . . . . . . . . . . . ."
    ". . . . . . . . . . . . ."
    ". . . . . . . . . . . . ."
    ". . . . . . . . . . . . ."
    ". . . . . . . . . . . . ."
    ". E11 E10 E9 E8 E7 E6 E5 E4 E3 E2 E1 ."
    ". D11 D10 D9 D8 D7 D6 D5 D4 D3 D2 D1 ."
    ". C11 C10 C9 C8 C7 C6 C5 C4 C3 C2 C1 ."
    ". B11 B10 B9 B8 B7 B6 B5 B4 B3 B2 B1 ."
    ". A11 A10 A9 A8 A7 A6 A5 A4 A3 A2 A1 .";
}
.QAT-Screen-04 {
  gap: 4px;
  grid-template-areas:
    "J13 J12 J11 J10 J9 J8 J7 J6 J5 J4 J3 J2 J1"
    ". . I9 I8 I7 I6 I5 I4 I3 I2 I1 . ."
    ". . H9 H8 H7 H6 H5 H4 H3 H2 H1 . ."
    ". G6 G5 G4 . . . . . G3 G2 G1 ."
    ". . . . . . . . . . . . ."
    ". . . . . . . . . . . . ."
    ". . . . . . . . . . . . ."
    ". . . . . . . . . . . . ."
    ". . . . . . . . . . . . ."
    ". . . . . . . . . . . . ."
    ". E11 E10 E9 E8 E7 E6 E5 E4 E3 E2 E1 ."
    ". D11 D10 D9 D8 D7 D6 D5 D4 D3 D2 D1 ."
    ". C11 C10 C9 C8 C7 C6 C5 C4 C3 C2 C1 ."
    ". B11 B10 B9 B8 B7 B6 B5 B4 B3 B2 B1 ."
    ". A11 A10 A9 A8 A7 A6 A5 A4 A3 A2 A1 .";
}
.QAT-Screen-05 {
  gap: 4px;
  grid-template-areas:
    "K13 K12 K11 K10 K9 K8 K7 K6 K5 K4 K3 K2 K1"
    ". J10 J9 J8 J7 J6 J5 J4 J3 J2 J1 . ."
    ". I10 I9 I8 I7 I6 I5 I4 I3 I2 I1 . ."
    ". H6 H5 H4 . . . . H3 H2 H1 . ."
    ". . . . . . . . . . . . ."
    ". . . . . . . . . . . . ."
    ". . . . . . . . . . . . ."
    ". . . . . . . . . . . . ."
    ". . . . . . . . . . . . ."
    ". . . . . . . . . . . . ."
    ". F11 F10 F9 F8 F7 F6 F5 F4 F3 F2 F1 ."
    ". E11 E10 E9 E8 E7 E6 E5 E4 E3 E2 E1 ."
    ". D11 D10 D9 D8 D7 D6 D5 D4 D3 D2 D1 ."
    ". C11 C10 C9 C8 C7 C6 C5 C4 C3 C2 C1 ."
    ". B11 B10 B9 B8 B7 B6 B5 B4 B3 B2 B1 ."
    ". A11 A10 A9 A8 A7 A6 A5 A4 A3 A2 A1 .";
}
.QAT-Screen-06 {
  gap: 4px;
  grid-template-areas:
    "K13 K12 K11 K10 K9 K8 K7 K6 K5 K4 K3 K2 K1"
    ". J10 J9 J8 J7 J6 J5 J4 J3 J2 J1 . ."
    ". I10 I9 I8 I7 I6 I5 I4 I3 I2 I1 . ."
    ". H6 H5 H4 . . . . H3 H2 H1 . ."
    ". . . . . . . . . . . . ."
    ". . . . . . . . . . . . ."
    ". . . . . . . . . . . . ."
    ". . . . . . . . . . . . ."
    ". . . . . . . . . . . . ."
    ". . . . . . . . . . . . ."
    ". F11 F10 F9 F8 F7 F6 F5 F4 F3 F2 F1 ."
    ". E11 E10 E9 E8 E7 E6 E5 E4 E3 E2 E1 ."
    ". D11 D10 D9 D8 D7 D6 D5 D4 D3 D2 D1 ."
    ". C11 C10 C9 C8 C7 C6 C5 C4 C3 C2 C1 ."
    ". B11 B10 B9 B8 B7 B6 B5 B4 B3 B2 B1 ."
    ". A11 A10 A9 A8 A7 A6 A5 A4 A3 A2 A1 .";
}

.QAT-Screen-07-IMAX {
  gap: 4px;
  grid-template-areas:
    ". . N20 N19 N18 N17 N16 N15 N14 N13 N12 N11 . . . . N10 N9 N8 N7 N6 N5 N4 N3 N2 N1 . ."
    ". M21 M20 M19 M18 M17 M16 . . . M15 M14 M13 M12 M11 M10 M9 M8 . . . M7 M6 M5 M4 M3 M2 ."
    ". L21 L20 L19 L18 L17 L16 . . . L15 L14 L13 L12 L11 L10 L9 L8 . . . L7 L6 L5 L4 L3 L2 L1"
    ". . . . K18 K17 K16 . . . K15 K14 K13 K12 K11 K10 K9 K8 . . . K7 K6 K5 . . . ."
    ". . . . J18 J17 J16 . . . J15 J14 J13 J12 J11 J10 J9 J8 . . . J7 J6 J5 . . . ."
    ". . . . . . . . . . H15 H14 H13 H12 H11 H10 H9 H8 . . . . . . . . . ."
    "G22 G21 G20 G19 G18 G17 G16 . . . G15 G14 G13 G12 G11 G10 G9 G8 . . . G7 G6 G5 G4 G3 G2 G1"
    "F22 F21 F20 F19 F18 F17 F16 . . . F15 F14 F13 F12 F11 F10 F9 F8 . . . F7 F6 F5 F4 F3 F2 F1"
    "E22 E21 E20 E19 E18 E17 E16 . . . E15 E14 E13 E12 E11 E10 E9 E8 . . . E7 E6 E5 E4 E3 E2 E1"
    "D22 D21 D20 D19 D18 D17 D16 . . . D15 D14 D13 D12 D11 D10 D9 D8 . . . D7 D6 D5 D4 D3 D2 D1"
    "C22 C21 C20 C19 C18 C17 C16 . . . C15 C14 C13 C12 C11 C10 C9 C8 . . . C7 C6 C5 C4 C3 C2 C1"
    "B22 B21 B20 B19 B18 B17 B16 . . . B15 B14 B13 B12 B11 B10 B9 B8 . . . B7 B6 B5 B4 B3 B2 B1"
    "A22 A21 A20 A19 A18 A17 A16 . . . A15 A14 A13 A12 A11 A10 A9 A8 . . . A7 A6 A5 A4 A3 A2 A1";
}

.QAT-Screen-08 {
  gap: 4px;
  grid-template-areas:
    ". . L14 L13 L12 L11 L10 L9 L8 L7 L6 L5 L4 L3 L2 L1 . . . ."
    ". . K12 K11 K10 K9 K8 . . K7 K6 K5 K4 K3 K2 K1 . . . ."
    ". . J12 J11 J10 J9 J8 . . J7 J6 J5 J4 J3 J2 J1 . . . ."
    ". . I12 I11 I10 I9 I8 . . I7 I6 I5 I4 I3 I2 I1 . . . ."
    ". . H12 H11 H10 H9 H8 . . H7 H6 H5 H4 H3 H2 H1 . . . ."
    ". . . . . . . . . . . . . . . . . . . ."
    ". . . . . . . . . . . . . . . . . . . ."
    ". . . . . . . . . . . . . . . . . . . ."
    ". . . . . . . . . . . . . . . . . . . ."
    ". . . . . . . . . . . . . . . . . . . ."
    ". . . . . . . . . . . . . . . . . . . ."
    "G15 G14 G13 G12 . . . . . . . . . . . . . . . ."
    "F15 F14 F13 F12  . . . F11 F10 F9 F8 F7 F6 F5 . . F4 F3 F2 F1"
    "E15 E14 E13 E12  . . . E11 E10 E9 E8 E7 E6 E5 . . E4 E3 E2 E1"
    "D15 D14 D13 D12  . . . D11 D10 D9 D8 D7 D6 D5 . . D4 D3 D2 D1"
    "C15 C14 C13 C12  . . . C11 C10 C9 C8 C7 C6 C5 . . C4 C3 C2 C1"
    "B15 B14 B13 B12  . . . B11 B10 B9 B8 B7 B6 B5 . . B4 B3 B2 B1"
    "A15 A14 A13 A12  . . . A11 A10 A9 A8 A7 A6 A5 . . A4 A3 A2 A1";
}
.QAT-Screen-09 {
  gap: 4px;
  grid-template-areas:
    ". . L14 L13 L12 L11 L10 L9 L8 L7 L6 L5 L4 L3 L2 L1 . . . ."
    ". . K12 K11 K10 K9 K8 . . K7 K6 K5 K4 K3 K2 K1 . . . ."
    ". . J12 J11 J10 J9 J8 . . J7 J6 J5 J4 J3 J2 J1 . . . ."
    ". . I12 I11 I10 I9 I8 . . I7 I6 I5 I4 I3 I2 I1 . . . ."
    ". . H12 H11 H10 H9 H8 . . H7 H6 H5 H4 H3 H2 H1 . . . ."
    ". . . . . . . . . . . . . . . . . . . ."
    ". . . . . . . . . . . . . . . . . . . ."
    ". . . . . . . . . . . . . . . . . . . ."
    ". . . . . . . . . . . . . . . . . . . ."
    ". . . . . . . . . . . . . . . . . . . ."
    ". . . . . . . . . . . . . . . . . . . ."
    "G15 G14 G13 G12 . . . . . . . . . . . . . . . ."
    "F15 F14 F13 F12  . . . F11 F10 F9 F8 F7 F6 F5 . . F4 F3 F2 F1"
    "E15 E14 E13 E12  . . . E11 E10 E9 E8 E7 E6 E5 . . E4 E3 E2 E1"
    "D15 D14 D13 D12  . . . D11 D10 D9 D8 D7 D6 D5 . . D4 D3 D2 D1"
    "C15 C14 C13 C12  . . . C11 C10 C9 C8 C7 C6 C5 . . C4 C3 C2 C1"
    "B15 B14 B13 B12  . . . B11 B10 B9 B8 B7 B6 B5 . . B4 B3 B2 B1"
    "A15 A14 A13 A12  . . . A11 A10 A9 A8 A7 A6 A5 . . A4 A3 A2 A1";
}

.QAT-Screen-01-VIP {
  gap: 4px;
  grid-template-areas:
    "F6 F5 . F4 F3 . F2 F1"
    "E6 E5 . E4 E3 . E2 E1"
    "D6 D5 . D4 D3 . D2 D1"
    "C6 C5 . C4 C3 . C2 C1"
    "B6 B5 . B4 B3 . B2 B1"
    "A6 A5 . A4 A3 . A2 A1";
}

.QAT-Screen-02-VIP {
  gap: 4px;
  grid-template-areas:
    "F6 F5 . F4 F3 . F2 F1"
    "E6 E5 . E4 E3 . E2 E1"
    "D6 D5 . D4 D3 . D2 D1"
    "C6 C5 . C4 C3 . C2 C1"
    "B6 B5 . B4 B3 . B2 B1"
    "A6 A5 . A4 A3 . A2 A1";
}

.QAT-Screen-03-VIP {
  gap: 4px;
  grid-template-areas:
    "F6 F5 . F4 F3 . F2 F1"
    "E6 E5 . E4 E3 . E2 E1"
    "D6 D5 . D4 D3 . D2 D1"
    "C6 C5 . C4 C3 . C2 C1"
    "B6 B5 . B4 B3 . B2 B1"
    "A6 A5 . A4 A3 . A2 A1";
}

.QAT-Screen-04-VIP {
  gap: 4px;
  grid-template-areas:
    "F6 F5 . F4 F3 . F2 F1"
    "E6 E5 . E4 E3 . E2 E1"
    "D6 D5 . D4 D3 . D2 D1"
    "C6 C5 . C4 C3 . C2 C1"
    "B6 B5 . B4 B3 . B2 B1"
    "A6 A5 . A4 A3 . A2 A1";
}